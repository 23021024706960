import dayjs from "dayjs";

export const useToastStore = defineStore("toast", {
  state: () => {
    return {
      message: "",
      timer: null,
      type: "success",

      // order received
      queue: [],
      orderMessage: "",
      orderTimer: null,
    };
  },

  actions: {
    show(data) {
      this.message = data.message;
      this.type = data.type || "success";

      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.message = "";
      }, data.timer || 10_000);

      //register click any thing in window to hide
      //window.addEventListener('click', this.hide)
    },

    hide() {
      this.message = "";
      clearTimeout(this.timer);

      //remove click any thing in window to hide
      window.removeEventListener("click", this.hide);
    },

    // order received
    showOrder(job) {
      this.orderMessage = `Table ${job.table} :  received order at ${dayjs(
        job.timestamp
      ).format("HH:mm:ss")}`;

      if (this.orderTimer) {
        clearTimeout(this.orderTimer);
      }

      this.orderTimer = setTimeout(() => {
        this.orderMessage = "";
      }, 5_000);
    },
  },
});
